import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
	getCookie,
	setCookie,
} from "@sportyano/core/common/utils/cookies-controller";
import { Languages } from "@sportyano/core/models/languages.model";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class LanguagesService {
	private readonly languageKey: string = "lang";
	private languageSubject: BehaviorSubject<string> =
		new BehaviorSubject<string>(Languages.Arabic);
	language$: Observable<string> = this.languageSubject.asObservable();

	constructor(
		private translate: TranslateService,
		@Inject(PLATFORM_ID) private platformId: object
	) {
		if (isPlatformBrowser(this.platformId)) {
			this.translate.setDefaultLang(Languages.Arabic);
			const savedLanguage =
				getCookie(this.languageKey) || Languages.Arabic;
			this.translate.setDefaultLang(savedLanguage);
			this.setCurrentLanguage(savedLanguage);
		}
	}

	setCurrentLanguage(selectedLanguage: string): void {
		this.translate.use(selectedLanguage);
		document.documentElement.dir =
			selectedLanguage === Languages.English ? "ltr" : "rtl";
		document.documentElement.lang =
			selectedLanguage === Languages.English ? "en" : "ar";
		setCookie(this.languageKey, selectedLanguage);
		this.languageSubject.next(selectedLanguage);
	}

	getSyncLanguageValue(): string {
		return this.languageSubject.value;
	}
}
